// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-wecp-design-system-button-js": () => import("./../../../src/pages/client/wecp/design-system/button.js" /* webpackChunkName: "component---src-pages-client-wecp-design-system-button-js" */),
  "component---src-pages-client-wecp-design-system-index-js": () => import("./../../../src/pages/client/wecp/design-system/index.js" /* webpackChunkName: "component---src-pages-client-wecp-design-system-index-js" */),
  "component---src-pages-client-wecp-index-js": () => import("./../../../src/pages/client/wecp/index.js" /* webpackChunkName: "component---src-pages-client-wecp-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-itilite-expense-js": () => import("./../../../src/pages/projects/itilite-expense.js" /* webpackChunkName: "component---src-pages-projects-itilite-expense-js" */),
  "component---src-pages-projects-wecp-js": () => import("./../../../src/pages/projects/wecp.js" /* webpackChunkName: "component---src-pages-projects-wecp-js" */)
}

